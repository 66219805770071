import axios, { AxiosResponse } from 'axios'
import { PdcSession } from './PdcSession'

interface PDCApiPost {
    url: string
    payload?: any
    extraHeaders?: any
}
interface PDCApiGet {
    url: string
    extraHeaders?: any
}
class PDCApiError extends Error {}

/**
 *
 */
export class PdcRestApi {
    private mAxios = axios.create()
    private mSession: PdcSession
    /**
     *
     */
    constructor (session: PdcSession) {
        this.mSession = session
    }

    /**
     *
     */
    private getHeader (extraHeaders) {
        const sessionInfo = PdcSession.getUserInfo()
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionInfo.sessionId}`,
                ...extraHeaders
            }
        }
    }

    /**
     *
     */
    private getBody (payload: any, accountLevel = false) {
        const sessionInfo = PdcSession.getUserInfo()
        return {
            ...payload,
            // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
            voip_id: sessionInfo.voipId,
            // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
            voip_phone_id: accountLevel ? null : sessionInfo.voipPhoneId
        }
    }

    /**
     *
     */
    private async responseInterceptor (axiosPromise: Promise<AxiosResponse<any>>): Promise<any> {
        return axiosPromise.then((response) => {
            const data = response.data
            if ('error' in response.data) throw Error('Error in response data')
            return {
                ...data
            }
        }).catch((error) => {
            throw new PDCApiError('API post responded with error: ' + error)
        })
    }

    /**
     * will take a json object and convert them to url params
     *
     * @param {object| string} params - current params or Json object to convert to params
     * @param {boolean}skipObjects - whether to skip the param field objects
     * @param {string}prefix - the prefix to the param objects
     */
    private toURLParamString (params: any | string, skipObjects = false, prefix = ''): string {
        const isObj = function (a: any) {
            if ((!!a) && (a.constructor === Object)) {
                return true
            }
            return false
        }
        const toParamArray = function (param: string, prefix: string) {
            return '' + (prefix !== '' ? '[' : '') + param + (prefix !== '' ? ']' : '')
        }
        let result = ''
        if (typeof (params) !== 'object') {
            return prefix + '=' + encodeURIComponent(params) + '&'
        }
        for (const param in params) {
            const c = '' + prefix + toParamArray(param, prefix)
            const paramString = params[param] as string
            if (isObj(params[param]) && !skipObjects) {
                result += this.toURLParamString(paramString, false, '' + c)
            } else if (Array.isArray(params[param]) && !skipObjects) {
                result += c + '=['
                for (let i = 0; i < paramString.length; i++) {
                    result += this.toURLParamString(paramString[i], true, '')
                    if (i < paramString.length - 1) {
                        result += ','
                    }
                }
                result += ']&'
                console.log(result)
            } else {
                result += c + '=' + encodeURIComponent(params[param]) + '&'
            }
        }
        return result
    }

    /**
     *
     */
    public async post (request: PDCApiPost): Promise<any> {
        const header = this.getHeader(request.extraHeaders || {})
        console.log('api post ', header, request)
        const body = this.getBody(request.payload || {})
        return this.responseInterceptor(this.mAxios.post(request.url, body, header))
    }

    /**
     *
     */
    public async get (request: PDCApiGet): Promise<any> {
        const header = this.getHeader(request.extraHeaders || {})
        return this.responseInterceptor(this.mAxios.get(request.url, header))
    }

    /**
     *
     */
    public async put (request: PDCApiPost): Promise<any> {
        const header = this.getHeader(request.extraHeaders || {})
        const body = this.getBody(request.payload || {})
        return this.responseInterceptor(this.mAxios.put(request.url, body, header))
    }

    /**
     *
     */
    public async patch (request: PDCApiPost): Promise<any> {
        const header = this.getHeader(request.extraHeaders || {})
        const body = this.getBody(request.payload || {})
        return this.responseInterceptor(this.mAxios.patch(request.url, body, header))
    }

    /**
     *
     */
    public async delete (request: PDCApiPost): Promise<any> {
        const header = this.getHeader(request.extraHeaders || {})
        const body = this.getBody(request.payload || {})
        const config = {
            data: body
        }
        const headerWithConfig = Object.assign(config, header)
        return this.responseInterceptor(this.mAxios.delete(request.url, headerWithConfig))
    }

    /**
     *
     */
    public async postAsGet (request: PDCApiPost): Promise<any> {
        const header = this.getHeader(request.extraHeaders || {})
        const body = this.getBody(request.payload || {})
        const urlParams = this.toURLParamString(body)
        const url = request.url + '?' + urlParams
        return this.responseInterceptor(this.mAxios.get(url, header))
    }
}
