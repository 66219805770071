import { SipClient, ISipClientDelegates } from './SipClient'
import { Call, CallDirection, CallState, CallUser } from './Call'
import { IUserInfo, PdcClientApp } from 'my-pdc-client'
import { ObservableState } from 'observable'
import { gtmPush } from 'gtm'
import inboundRing from './ringtones/ring-sound.mp3'
import outboundRing from './ringtones/ring-back-sound.mp3'
import callWaitingRing from './ringtones/call-waiting-sound.mp3'
/**
 *
 */
export interface PhoneNumber {
    number: string
    label: string
    location: {
        country: string
        state: string
        city: string
    }
}
interface CallClientState {
    initialized: boolean
    connected: boolean
    registered: boolean
    ready: boolean
    calls: Call[]
    callerId: PhoneNumber
    phoneNumbers: PhoneNumber[]
    audioRecordingEnabled: boolean
    audioPlaybackEnabled: boolean
    inboundRingAudioEnabled: boolean
    outboundRingAudioEnabled: boolean
    callWaitingRingAudioEnabled: boolean
    ringtoneAudioVolume: number
    recordingDevice: MediaDeviceInfo
    playbackDevice: MediaDeviceInfo
}
type CallClientStateCallback = (state: CallClientState) => void
interface PdcCallConfig {
    listener: CallClientStateCallback
    recordingDevice: MediaDeviceInfo
    playbackDevice: MediaDeviceInfo
}
/**
 *
 */
class PdcCallClient extends ObservableState<CallClientState> {
    private mPdcClient: PdcClientApp
    private mSipClient: SipClient
    private mClientInitializePromise: Promise<void>
    private mInboundRingAudio: HTMLAudioElement
    private mCallWaitingRingAudio: HTMLAudioElement
    private mInboundRingAudioPromise = Promise.resolve()
    private mCallWaitingRingAudioPromise = Promise.resolve()
    private mOutboundRingAudio: HTMLAudioElement
    private mOutboundRingAudioPromise = Promise.resolve()
    private mSipClientDelegates: ISipClientDelegates = {
        onIncomingCall: (call: Call) => {
            console.log('onIncomingCall')
            const user = call.users[0]
            this.mPdcClient.store().getContacts(user.phoneNumber).then((contacts) => {
                if (contacts.length) user.setContactInfo(contacts[0])
            })
            this.state?.calls.length > 1 ? this.startCallWaitingRingAudio() : this.startInboundRingAudio()
            gtmPush({ name: 'PDC_web_sip_inbound_call' })
        },
        onOutgoingCall: (call: Call) => {
            console.log('onOutgoingCall')
            const user = call.users[0]
            this.mPdcClient.store().getContacts(user.phoneNumber).then((contacts) => {
                if (contacts.length) user.setContactInfo(contacts[0])
            })
            this.startOutboundRingAudio()
            gtmPush({ name: 'PDC_web_sip_outbound_call' })
        },
        onCallsListChange: (calls: Call[]) => {
            this.setState({
                calls
            })
            this.updateRingtones()
        },
        onClientRegistered: (isRegistered: boolean) => {
            this.setState({
                registered: isRegistered,
                ready: isRegistered && this.state?.connected
            })
        },
        onClientConnected: () => {
            this.setState({
                connected: true,
                ready: this.state?.registered
            })
        },
        onClientDisconnected: (error) => {
            this.setState({
                connected: false,
                ready: false
            })
        }
    }

    /**
     *
     */
    constructor (app: PdcClientApp, config: PdcCallConfig) {
        super({
            initialized: false,
            connected: false,
            registered: false,
            ready: false,
            calls: [],
            callerId: { number: '', label: '', location: { country: '', state: '', city: '' } },
            audioRecordingEnabled: false,
            audioPlaybackEnabled: false,
            inboundRingAudioEnabled: true,
            outboundRingAudioEnabled: true,
            callWaitingRingAudioEnabled: true,
            ringtoneAudioVolume: 1.0,
            phoneNumbers: [],
            recordingDevice: config.recordingDevice,
            playbackDevice: config.playbackDevice
        })
        this.mInboundRingAudio = new Audio(inboundRing)
        this.mInboundRingAudio.loop = true
        this.mOutboundRingAudio = new Audio(outboundRing)
        this.mOutboundRingAudio.loop = true
        this.mCallWaitingRingAudio = new Audio(callWaitingRing)
        this.mCallWaitingRingAudio.loop = true
        this.subscribe(config.listener)
        this.mPdcClient = app
        this.mClientInitializePromise = this.initialize().then(() => {
            this.setState({
                initialized: true
            })
        })
        console.log('PdcCallClient initialized: ', this.state?.recordingDevice, this.state?.playbackDevice)
    }

    /**
     *
     */
    private async initialize (): Promise<void> {
        const userInfo = PdcClientApp.user()
        await this.fetchPhoneNumbers(userInfo).then(res => {
            const sorted = res.sort((a, b) => {
                return a.number.localeCompare(b.number)
            })
            this.setState({ phoneNumbers: sorted })
        })
        await this.fetchCallerId(userInfo).then(res => this.setState({ callerId: res }))
        const sipAuthenticaiton = userInfo?.sipAuthenticaiton
        console.log('sip host: ', sipAuthenticaiton?.host)
        const devices = {
            input: this.state?.recordingDevice,
            output: this.state?.playbackDevice
        }
        this.mSipClient = new SipClient(sipAuthenticaiton, this.mSipClientDelegates, devices)
        this.enableAudioPlayback(true)
        this.enableAudioRecording(true)
    }

    /**
     *
     */
    private async fetchCallerId (userInfo: IUserInfo): Promise<PhoneNumber> {
        const request = {
            url: 'https://my.phone.com/api/app/communicator/calls/get-caller-id',
            payload: {
                // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
                extension_id: userInfo?.voipPhoneId
            }
        }
        return PdcClientApp.api().post(request).then(res => {
            return this.state?.phoneNumbers.find(phoneNumber => phoneNumber.number === res.caller_id)
        })
    }

    /**
     *
     */
    private async fetchPhoneNumbers (userInfo: IUserInfo): Promise<PhoneNumber[]> {
        const request = {
            url: 'https://my.phone.com/api/app/user/list-phone-numbers',
            payload: {
                // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
                extension_id: userInfo?.voipPhoneId
            }
        }
        return await PdcClientApp.api().post(request).then(res => res.calling as PhoneNumber[])
    }

    public setCallerId = async (callerId: string): Promise<void> => {
        const request = {
            url: 'https://my.phone.com/api/app/communicator/calls/set-caller-id',
            payload: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                caller_id: callerId
            }
        }
        return await PdcClientApp.api().post(request).then(res => res.caller_id)
    }

    /**
     *
     */
    public startCall = async (number: string): Promise<Call> => {
        if (this.state?.ready) {
            return await this.mSipClient.startCall(number).then((call) => {
                // call.setInputAudioDevice(this.state?.recordingDevice)
                call.setOutputAudioDevice(this.state?.playbackDevice)
                return call
            })
        }
        throw new Error('PDC calling client is not ready')
    }

    /**
     *
     */
    public startClient = async (): Promise<void> => {
        return this.mClientInitializePromise.then(() => this.mSipClient.startClient())
    }

    /**
     *
     */
    public stopClient = async (): Promise<void> => {
        return this.mClientInitializePromise.then(() => {
            for (const call of (this.state?.calls || [])) {
                call.terminate()
            }
            this.mSipClient.stopClient()
        })
    }

    public enableAudioPlayback = (enable: boolean): void => {
        this.mSipClient?.setAudioOutputEnabled(enable)
        this.setState({ audioPlaybackEnabled: this.mSipClient?.isAudioOutputEnabled() })
    }

    public enableAudioRecording = (enable: boolean): void => {
        this.mSipClient?.setAudioInputEnabled(enable)
        this.setState({ audioRecordingEnabled: this.mSipClient?.isAudioInputEnabled() })
    }

    /** Start Ringtones */

    private startInboundRingAudio = async () => {
        if (!this.state?.inboundRingAudioEnabled) return
        this.mInboundRingAudioPromise.then(() => {
            this.mInboundRingAudioPromise = this.mInboundRingAudio.play().catch((error) => {
                console.error('Error playing inbound ringtone: ', error)
            })
        })
    }

    private startCallWaitingRingAudio = (): void => {
        if (!this.state?.callWaitingRingAudioEnabled) return
        this.mCallWaitingRingAudioPromise.then(() => {
            this.mCallWaitingRingAudioPromise = this.mCallWaitingRingAudio.play().catch((error) => {
                console.error('Error playing call waiting ringtone: ', error)
            })
        })
    }

    private startOutboundRingAudio = (): void => {
        if (!this.state?.outboundRingAudioEnabled) return
        this.mOutboundRingAudioPromise.then(() => {
            this.mOutboundRingAudioPromise = this.mOutboundRingAudio.play().catch((error) => {
                console.error('Error playing outbound ringtone: ', error)
            })
        })
    }

    private stopInboundRingAudio = () => {
        this.mInboundRingAudioPromise.then(() => this.mInboundRingAudio.pause())
        this.mCallWaitingRingAudioPromise.then(() => this.mCallWaitingRingAudio.pause())
    }

    private stopOutboundRingAudio = (): void => {
        this.mOutboundRingAudioPromise.then(() => this.mOutboundRingAudio.pause())
    }

    private updateRingtones = () => {
        const incomingCalls = this.state?.calls.filter((call) => call.state === CallState.Connecting && call.direction === CallDirection.Incoming)
        if (incomingCalls.length < 1) {
            this.stopInboundRingAudio()
        }
        const outgoingCalls = this.state?.calls.filter((call) => call.state === CallState.Connecting && call.direction === CallDirection.Outgoing)
        if (outgoingCalls.length < 1) {
            this.stopOutboundRingAudio()
        }
    }

    public setInboundRingAudioEnabled = (enabled: boolean): void => {
        this.setState({ inboundRingAudioEnabled: enabled })
        if (!enabled) this.stopInboundRingAudio()
    }

    public setCallWaitingRingAudioEnabled = (enabled: boolean): void => {
        this.setState({ callWaitingRingAudioEnabled: enabled })
        if (!enabled) this.stopInboundRingAudio()
    }

    public setOutboundRingAudioEnabled = (enabled: boolean): void => {
        this.setState({ outboundRingAudioEnabled: enabled })
        if (!enabled) this.stopOutboundRingAudio()
    }

    public setRingVolume = (volume: number): void => {
        this.mInboundRingAudio.volume = volume
        this.mOutboundRingAudio.volume = volume
        this.mCallWaitingRingAudio.volume = volume
        this.setState({ ringAudioVolume: volume })
    }
    /** End Ringtones */

    /** Audio Devices */
    public setRecordingDevice = (device: MediaDeviceInfo): void => {
        this.mSipClient.setInputAudioDevice(device)
        this.setState({ recordingDevice: device })
    }

    public setPlaybackDevice = (device: MediaDeviceInfo): void => {
        this.mSipClient.setOutputAudioDevice(device)
        this.mInboundRingAudio.setSinkId(device.deviceId)
        this.mOutboundRingAudio.setSinkId(device.deviceId)
        this.mCallWaitingRingAudio.setSinkId(device.deviceId)
        this.setState({ playbackDevice: device })
    }

    public getRecordingDevice = (): MediaDeviceInfo => {
        return this.state?.recordingDevice
    }

    public getPlaybackDevice = (): MediaDeviceInfo => {
        return this.state?.playbackDevice
    }
    /** End Audio Devices */
}

/**
 *
 */
export { Call, CallUser, CallDirection, CallState, PdcCallClient, CallClientStateCallback, CallClientState }
