import { fetch, RequestInit } from 'whatwg-fetch'
import { IUserInfo, ISipAuthenticaiton } from './data-store/db'
import { db } from './data-store/db/MyPdcDexie'

class PdcSession {
    private static GetUserInfoURL = 'https://app.phone.com/communicator/get-user-info/'
    private static TokenCookieName = 'pdc-client-session-token'
    private static sessionId: string
    private static redirect: () => void
    private static mInstance: PdcSession
    private static mUserInfo: IUserInfo
    // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
    /**
     *
     */
    private constructor (sessionToken: string, redirect: () => void) {
        if (PdcSession.mInstance) {
            return PdcSession.mInstance
        } else {
            PdcSession.mInstance = this
        }
        PdcSession.sessionId = sessionToken
        PdcSession.redirect = redirect
    }

    private static fetchUserInfo = async (): Promise<IUserInfo> => {
        const sessionToken = PdcSession.sessionId
        const url = PdcSession.GetUserInfoURL
        const token = `Bearer ${sessionToken}`
        const fetchBody: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            },
            credentials: 'omit'
        }
        const getUserInfoResponse = await fetch(url, fetchBody)
        const userInfo = await getUserInfoResponse.json()
        console.log('userInfo: ', userInfo)
        if (userInfo.account_id === 'undefined') {
            throw new Error('Unable to get account id')
        }
        // const numberInfo = await PdcSession.fetchNumberInfo()
        PdcSession.mUserInfo = {
            sessionId: sessionToken,
            voipId: userInfo.account_id,
            voipPhoneId: userInfo.extension_id,
            role: userInfo.role,
            userId: userInfo.user_id,
            userType: userInfo.user_account_type,
            legacyUser: (typeof userInfo.user_id !== 'number'),
            sipAuthenticaiton: null,
            extensions: userInfo.extensions
        }
        return PdcSession.mUserInfo
    }

    private static fetchSipAuthentication = async (userInfo: IUserInfo): Promise<ISipAuthenticaiton> => {
        const baseURL = 'https://api.phone.com/v4'
        const requestUrl = `${baseURL}/accounts/${userInfo.voipId}/extensions/${userInfo.voipPhoneId}`
        const token = `Bearer ${userInfo.sessionId}`
        const fetchBody: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            },
            credentials: 'omit'
        }
        const response = await fetch(requestUrl, fetchBody)
        const data = await response.json()
        const sipAuthenticaiton = data?.device_membership?.device?.sip_authentication
        PdcSession.mUserInfo.sipAuthenticaiton = sipAuthenticaiton
        return sipAuthenticaiton
    }

    /**
     *
     */
    public static async initialize (sessionToken: string, redirect: () => void): Promise<PdcSession> {
        try {
            if (sessionToken === '') {
                sessionToken = localStorage.getItem(PdcSession.TokenCookieName)
                if (!sessionToken) {
                    redirect()
                    throw new Error('Session token is empty')
                }
            }
            const session = new PdcSession(sessionToken, redirect)
            localStorage.setItem(PdcSession.TokenCookieName, sessionToken)
            const userInfo = await this.fetchUserInfo()
            userInfo.sipAuthenticaiton = await this.fetchSipAuthentication(userInfo)
            await db.userInfo.clear()
            await db.userInfo.put(userInfo)
            return Promise.resolve(session)
        } catch (error) {
            redirect()
            throw error
        }
    }

    /**
     *
     */
    public static logout (): void {
        localStorage.removeItem(PdcSession.TokenCookieName)
        PdcSession.redirect()
    }

    /**
     *
     */
    public static getUserInfo (): IUserInfo {
        return PdcSession.mUserInfo
    }
}

/**
 *
 */
export { PdcSession }
