/**
 *
 */
const styles = (theme) => ({
    loadingScreenRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
        '& .logo-root': {
            position: 'absolute',
            bottom: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .timeout-message': {
            margin: '16px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }
    }
})

export default styles
