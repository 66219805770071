import React, { useState, useContext, useEffect } from 'react'
import { MenuItem, Select, Slider, makeStyles } from '@material-ui/core'
import { settingsStyles } from './styles'
import Typography from 'typography'
import Grid from '@material-ui/core/Grid'
import { Button } from 'button-mui'
import { WebPhoneContext } from '../../WebPhoneContext'
import Switch from 'switch'
import IconButton from 'icon-button-mui'
import { CloseIcon } from 'svg-icons'
import Divider from '@material-ui/core/Divider'
import { VolumeDownIcon, VolumeUpIcon } from 'svg-icons/src'
import { LoadingComponent } from './../LoadingScreen'
import { DeleteIntegration } from '../DeleteIntegration'
import { SignOutComponent } from './../SignOut/index'
import { AudioDevicesContext } from 'audio-devices'

const useStyles = makeStyles(settingsStyles)

function DeleteIntegrationSection ({ openDeleteIntegrationPopupHandler }: { openDeleteIntegrationPopupHandler: (value: boolean) => void }): JSX.Element {
    return (
        // todo remove window direct ref once integration context is made
        (window.pdcIntegrationID) ? <Button variant='contained' color='urgent' className='disconnect-section-btn' onClick={() => openDeleteIntegrationPopupHandler(true)}>Disconnect Zoho</Button> : <></>
    )
}

function InboundRingAudioSection (): JSX.Element {
    const webPhoneContext = useContext(WebPhoneContext)
    const enabled = webPhoneContext.inboundRingAudioEnabled

    return (<div className={'settings-section-item-content'}>
        <Switch
            value={enabled}
            checked={enabled}
            onChange={() => webPhoneContext.enableInboundRingAudio(!enabled)}
            name={'inbound-ring-tone'}
        />
    </div>)
}

function OutboundRingAudioSection (): JSX.Element {
    const webPhoneContext = useContext(WebPhoneContext)
    const enabled = webPhoneContext.outboundRingAudioEnabled

    return (<div className={'settings-section-item-content'}>
        <Switch
            value={enabled}
            checked={enabled}
            onChange={() => webPhoneContext.enableOutboundRingAudio(!enabled)}
            name={'outbound-ring-tone'}
        />
    </div>)
}

function CallWaitingRingAudioSection (): JSX.Element {
    const webPhoneContext = useContext(WebPhoneContext)
    const enabled = webPhoneContext.callWaitingRingAudioEnabled

    return (<div className={'settings-section-item-content'}>
        <Switch
            value={enabled}
            checked={enabled}
            onChange={() => webPhoneContext.enableCallWaitingRingAudio(!enabled)}
            name={'call-waiting-tone'}
        />
    </div>)
}

function PlaybackDeviceSelector (): JSX.Element {
    const audioDevices = useContext(AudioDevicesContext)
    const webPhone = useContext(WebPhoneContext)
    const [devices, setDevices] = useState(audioDevices.outputDevices)
    const [selectedDevice, setSelectedDevice] = useState(webPhone.getPlaybackDevice())
    console.log('PdcCallClient Playback Device', selectedDevice)
    useEffect(() => {
        setDevices(audioDevices.outputDevices)
    }, [audioDevices.outputDevices])
    const onSelect = (event) => {
        const deviceId = event.target.value
        const device = devices.find(device => device.deviceId === deviceId)
        setSelectedDevice(device)
        webPhone.setPlaybackDevice(device)
    }
    if (!devices.length) return (<></>)
    return (<div className='device-section'>
        <Typography className='section-heading'>Speaker</Typography>
        <Select
            id="playback-select"
            value={selectedDevice.deviceId}
            onChange={onSelect}
            className='device-selector'
            style={{ width: '100%' }}
        >
            {devices.map((device: MediaDeviceInfo) => {
                let label = device.label
                if (label === '') label = 'Speaker'
                return <MenuItem key={device.deviceId} value={device.deviceId}>{label}</MenuItem>
            })}
        </Select>
    </div>)
}

function RecordingDeviceSelector (): JSX.Element {
    const audioDevices = useContext(AudioDevicesContext)
    const webPhone = useContext(WebPhoneContext)
    const [devices, setDevices] = useState(audioDevices.inputDevices)
    const [selectedDevice, setSelectedDevice] = useState(webPhone.getRecordingDevice())
    console.log('PdcCallClient Recording Device', selectedDevice)
    useEffect(() => {
        setDevices(audioDevices.inputDevices)
    }, [audioDevices.inputDevices])
    const onSelect = (event) => {
        const deviceId = event.target.value
        const device = devices.find(device => device.deviceId === deviceId)
        setSelectedDevice(device)
        webPhone.setRecordingDevice(device)
    }
    if (!devices.length) return (<></>)
    return (<div className='device-section'>
        <Typography className='section-heading'>Microphone</Typography>
        <Select
            id="recording-select"
            value={selectedDevice.deviceId}
            onChange={onSelect}
            className='device-selector'
            style={{ width: '100%' }}
        >
            {devices.map((device: MediaDeviceInfo) => {
                let label = device.label
                if (label === '') label = 'Microphone'
                return <MenuItem key={device.deviceId} value={device.deviceId}>{label}</MenuItem>
            })}
        </Select>
    </div>)
}

const SectionItem = ({ heading, details, switchComponent }: {heading?: string, details?: string, switchComponent: JSX.Element}) => {
    return (
        <Grid item container>
            <Grid item xs={9}>
                {heading && <Typography className='section-item-heading'>{heading}</Typography>}
                {details && <Typography className='section-item-details'>{details}</Typography>}
            </Grid>
            <Grid item xs={3} className='switch-container'>
                {switchComponent}
            </Grid>
        </Grid>
    )
}

const VolumeSection = () => {
    const webPhoneContext = useContext(WebPhoneContext)
    const [volume, setVolume] = React.useState(webPhoneContext.ringtoneVolume * 100)

    const adjustVolume = (mode: 'DECREASE'|'INCREASE') => {
        const currentVolume = volume
        let newVolume = currentVolume + 10
        if (mode === 'DECREASE') newVolume = currentVolume - 10
        if (newVolume < 0) {
            newVolume = 0
        } else if (newVolume > 100) {
            newVolume = 100
        }
        webPhoneContext.setRingVolume((newVolume as number) / 100)
        setVolume(newVolume as number)
    }

    return (
        <Grid item container className='volume-container'>
            <Grid item >
                <IconButton size='small' className='iconBtn'>
                    <VolumeDownIcon onClick={() => adjustVolume('DECREASE')}/>
                </IconButton>
            </Grid>
            <Grid item xs={7}>
                <Slider
                    value = {volume}
                    onChange = {(e, volume) => {
                        webPhoneContext.setRingVolume((volume as number) / 100)
                        setVolume(volume as number)
                        console.log('Ringtone Volume', volume)
                    }}
                    aria-labelledby = 'continuous-slider'
                />
            </Grid>
            <Grid item >
                <IconButton size='small' className='iconBtn'>
                    <VolumeUpIcon onClick={() => adjustVolume('INCREASE')}/>
                </IconButton>
            </Grid>
        </Grid>
    )
}

const AppVersionSection = () => {
    const readableAppVersion = () => {
        return `${process.env.REACT_APP_BUILD_DATE}@${process.env.REACT_APP_BUILD_VERSION?.substring(0, 6)} (${process.env.REACT_APP_STAGE})`
    }
    return (
        <Typography variant='subtitle1' color={'secondary'} className='app-version'>{'Version ' + readableAppVersion()}</Typography>
    )
}

/**
 *
 */
export function PhoneSettings (props: { onClose: () => void }): JSX.Element {
    const classes = useStyles()
    const [openDeleteIntegrationPopup, setOpenDeleteIntegrationPopup] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const webPhoneContext = useContext(WebPhoneContext)
    const disconnectZoho = webPhoneContext.actions?.deleteIntegration

    const CloseScreen = ({ onClose }: { onClose: () => void }) => (
        <IconButton
            variant = 'contained-light'
            size = 'small'
            color = 'secondary'
            onClick = {onClose}
            classes = {{ root: `${classes.closeButton} disable-dragging` }}
        >
            <CloseIcon/>
        </IconButton>
    )
    const openDeleteIntegrationPopupHandler = (value: boolean) => setOpenDeleteIntegrationPopup(value)

    const disconnectHandle = () => {
        setShowLoader(true)
        disconnectZoho()
    }

    return (
        <>
            <div className={classes.settingsRoot} data-testid='settingsRoot'>
                <CloseScreen onClose={props.onClose} />
                <div className={'settings'}>
                    <Grid className='tones-section' container direction='column'>
                        <Grid item>
                            <Typography className='section-heading'>Standard Tones</Typography>
                        </Grid>
                        <Grid item container className='section-root'>
                            <SectionItem heading='Ringtone' switchComponent={<InboundRingAudioSection />} />
                            <SectionItem heading='Dialtone' switchComponent={<OutboundRingAudioSection />} />
                            <SectionItem heading='Call Waiting Tone' switchComponent={<CallWaitingRingAudioSection />} />
                        </Grid>
                    </Grid>
                    <Divider className={'divider'} />
                    <Grid className='volume-section' container direction='column'>
                        <Grid item>
                            <Typography className='section-heading'>Volume</Typography>
                        </Grid>
                        <Grid item container className='section-root'>
                            <VolumeSection />
                        </Grid>
                    </Grid>
                    <Divider className={'divider'} />
                    <Grid className='device-section' container spacing={2} direction='column'>
                        <Grid item xs={12}><PlaybackDeviceSelector/></Grid>
                        <Grid item xs={12}><RecordingDeviceSelector/></Grid>
                    </Grid>
                    <Divider className={'divider'} />
                    <AppVersionSection />
                    <Grid className='disconnect-section' container direction='column'>
                        <Grid item className='section-root'>
                            <DeleteIntegrationSection openDeleteIntegrationPopupHandler={openDeleteIntegrationPopupHandler} />
                        </Grid>
                    </Grid>
                </div>
                <SignOutComponent />
            </div>
            {
                openDeleteIntegrationPopup &&
                <div className={classes.settingsRoot}>
                    {
                        !showLoader ? <DeleteIntegration disconnectHandle={disconnectHandle} openDeleteIntegrationPopupHandler={openDeleteIntegrationPopupHandler} closePopupComponent={<CloseScreen onClose={() => { openDeleteIntegrationPopupHandler(false) }} />} /> : <LoadingComponent />
                    }
                </div>
            }
        </>
    )
}
